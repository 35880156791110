<template>
  <div class="readView">
    <div @click="isRead? $emit('input', !value): onClick(true)">
      <span class="mint-checkbox">
        <input class="mint-checkbox-input" type="checkbox" :checked="value">
        <span class="mint-checkbox-core"></span>
      </span>
      <span>{{ $t('clickApplyAgree') }}</span>
      <span class="val" @click.stop="onClickUrl">{{ title }}</span>
    </div>
    <div class="error" v-if="error">{{ error }}</div>
    <mt-popup v-if="popupVisible" v-model="popupVisible">
      <div class="content" ref="content"></div>
      <div class="bottom">
        <div class="tips" @click="onClick">
          <span class="mint-checkbox">
            <input class="mint-checkbox-input" type="checkbox" :checked="value">
            <span class="mint-checkbox-core"></span>
          </span>
          <span>{{ $t('readViewTips1') }}</span>
        </div>
        <div class="error" v-if="errorVal">{{ errorVal }}</div>
        <div class="box">
          <mt-button type="default" size="large" @click="popupVisible = false">{{
            $t("cancel")
          }}</mt-button>
          <mt-button type="primary" size="large" @click="submit">{{
            $t("confirm")
          }}</mt-button>
        </div>
      </div>
    </mt-popup>
  </div>
</template>

<script>
export default {
  name: 'ReadView',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    src: {
      type: String,
      default: '',
    },
    click: {
      type: Function,
    }
  },
  data () {
    return {
      isCheck: false,
      isRead: false,
      popupVisible: false,
      error: '',
    }
  },
  computed: {
    errorVal() {
      if (this.isCheck) {
        if (!this.isRead) {
          return this.$t('readViewTips2')
        } else if (!this.value) {
          return this.$t('readViewTips3')
        }
      }
      return ''
    },
    isLoad() {
      return this.popupVisible && !!this.src
    }
  },
  watch: {
    popupVisible(val) {
      if (val && this.click) {
        this.click()
      }
    },
    isLoad(val) {
      if (val) {
        this.$nextTick(() => {
          let iframe = document.createElement('iframe')
          iframe.setAttribute('frameborder', '0')
          iframe.setAttribute('height', '100%')
          iframe.setAttribute('width', '100%')
          if (/^http/.test(this.src)) {
            iframe.src = this.src
          } else {
            iframe.src = 'about:blank'
          }
          iframe.onload = () => {
            let self = this
            try {
              if (!/^http/.test(this.src)) {
                iframe.contentDocument.write(this.src)
              }
              iframe.contentWindow.onscroll = scroll
              scroll()
            } catch(e) {
              self.isRead = true
            }
            function scroll() {
              var ifm = iframe.contentWindow.document.documentElement
              if (ifm.scrollTop >= ifm.scrollHeight - ifm.clientHeight - 50) {
                self.isRead = true
              }
            }
          }
          this.$refs.content.appendChild(iframe)
        })
      }
    }
  },
  mounted() {
    
  },
  methods: {
    onClick(val) {
      this.isCheck = true
      if (this.isRead) {
        this.$emit('input', !this.value)
      }
      if (val) {
        this.popupVisible = true
      }
    },
    onClickUrl() {
      this.popupVisible = true
    },
    submit() {
      this.isCheck = true
      if (this.value) {
        this.popupVisible = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.readView {
  padding: 10px 0;
  text-align: center;
  font-size: 14px;
  color: #4F9B41;
  .val {
    color: black;
    text-decoration: underline;
  }
  .error {
    color: crimson;
    font-size: 12px;
    padding-bottom: 10px;
  }
  .mint-checkbox {
    vertical-align: bottom;
    margin-right: 10px;
  }
  .mint-popup {
    width: 90vw;
    height: 90vh;
    display: flex;
    flex-direction: column;
    .content {
      flex: 1;
    }
    .bottom {
      border-top: 1px solid #eaeaea;
      padding: 10px 20px 15px 20px;
      background-color: white;
      .tips {
        padding-bottom: 10px;
      }
      .mint-button {
        border-radius: 6px;
      }
      .box {
        display: flex;
        .mint-button {
          font-size: 13px;
          margin: 0 10px;
        }
      }
    }
  }
}
</style>