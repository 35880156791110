<template>
  <div class="register">
    <mt-header :title="$t('register')">
      <router-link to slot="left">
        <mt-button @click="$router.back(-1)">
          <img src="@/assets/back.png" height="20" width="20" slot="icon">
          {{ $t('back') }}
        </mt-button>
      </router-link>
      <call-phone slot="right" />
    </mt-header>
    <div class="content">
      <div class="item">
        <div class="title">{{ $t('phone') }}</div>
        <div class="phone">
          <mt-field
            :placeholder="$t('phonePlaceholder')"
            v-model="form.phone"
            type="tel"
            v-mask="'#### #### ##'"
            :state="phoneState"
          ></mt-field>
        </div>
      </div>
      <div class="item">
        <div class="title">{{ $t('password') }}</div>
        <mt-field
          :placeholder="$t('passwordPlaceholder')"
          :type="!visible? 'password': 'text'"
          v-model="form.password"
          :state="passwordState"
        >
          <img v-if="!visible" class="visible" src="../../assets/notVisible.png" height="20px" @click="visible = true">
          <img v-else class="visible" src="../../assets/browse.png" height="20px" @click="visible = false">
        </mt-field>
      </div>
      <div class="item">
        <div class="title">{{ $t('confirmPassword') }}</div>
        <mt-field
          :placeholder="$t('confirmPasswordPlaceholder')"
          :type="!newVisible? 'password': 'text'"
          v-model="form.comfirmPassword"
          :state="comfirmPasswordState"
        >
          <img v-if="!newVisible" class="visible" src="../../assets/notVisible.png" height="20px" @click="newVisible = true">
          <img v-else class="visible" src="../../assets/browse.png" height="20px" @click="newVisible = false">
        </mt-field>
      </div>
      <div class="item">
        <div class="title">{{ $t('validateCode') }}</div>
        <div class="code">
          <mt-field :placeholder="$t('codePlaceholder')" v-model="form.validateCode" :state="validateCodeState" />
          <mt-button type="primary" size="small" plain :disabled="!!time" @click="getSmsCode('REGISTER')">{{
            $t("getVerificationCode")
          }}{{ time? `(${time})`: '' }}</mt-button>
        </div>
        <!-- <div class="ivr">
          {{ $t("youCanTry") }}
          <mt-button size="small" @click="getIvrCode('REGISTER')" :disabled="!!time">{{ $t("getIvrCode") }}{{ time? `(${time})`: '' }}</mt-button>
        </div> -->
      </div>
    </div>
    <div class="bottom">
      <mt-button type="primary" size="large" @click="submit">{{
        $t("register")
      }}</mt-button>
      <read-view v-model="checked" :src="privacyUrl" :title="$t('serviceAgreement')" />
    </div>
    <div class="login">
      {{ $t('loginTips') }}
      <span class="btn" @click="onLogin">
        {{ $t('loginNow') }}
      </span>
    </div>
  </div>
</template>

<script>
import { phoneRules, validateCodeRules, passwordRules, comfirmPasswordRules } from "../../utils/rules";
import { mapState, mapMutations } from "vuex";
import { registeredUser } from '../../utils/api'
import callPhone from '@/components/callPhone.vue'
import readView from '@/components/readView.vue'
import mixin from './mixin'
import branch from '../../../node_modules/branch-sdk'
import Vue from 'vue'
import gtmPageView from '../../utils/gtm.js'
export default {
  name: 'Register',
  components: {
    callPhone,
    readView,
  },
  mixins: [mixin],
  props: ['value'],
  data() {
    return {
      checked: false,
      visible: false,
      newVisible: false,
    }
  },
  computed: {
    ...mapState(["record", "brand"]),
    privacyUrl() {
      if (this.brand.channelInfo) {
        return this.brand.channelInfo.privacyUrl
      }
      return ''
    },
    phoneState() {
      this.SET_RECORD_ITEM({
        key: "registerPhone",
        value: this.form.phone,
      });
      return this.isCheck ? phoneRules(this.form.phone) : null;
    },
    validateCodeState() {
      return this.isCheck ? validateCodeRules(this.form.validateCode) : null;
    },
    passwordState() {
      this.SET_RECORD_ITEM({
        key: "registerPassword",
        value: this.form.password,
      });
      return this.isCheck ? passwordRules(this.form.password) : null;
    },
    comfirmPasswordState() {
      this.SET_RECORD_ITEM({
        key: "comfirmRegisterPassword",
        value: this.form.comfirmPassword,
      });
      return this.isCheck ? comfirmPasswordRules(this.form.comfirmPassword, this.form.password) : null;
    }
  },
  watch: {
    checked(val) {
      this.SET_CHECKED_SERVICE_AGREEMENT(val)
    }
  },
  created() {
    gtmPageView(this)
    this.checked = this.$store.state.checkedServiceAgreement || false;
    this.form.phone = this.record.registerPhone || "";
    this.form.password = this.record.registerPassword || "";
    this.form.password = this.record.comfirmRegisterPassword || "";
  },
  methods: {
    ...mapMutations(["SET_RECORD_ITEM", "SET_ACCESS_TOKEN", "SET_CHECKED_SERVICE_AGREEMENT"]),
    onLogin() {
      this.SET_RECORD_ITEM({
        key: "loginPhone",
        value: this.form.phone,
      });
      this.$router.back(-1)
    },
    async submit() {
      if (!this.checked) {
        this.$toast(this.$t('readViewTips2'));
        return;
      }
      this.isCheck = true;
      var error = this.validation(['phone', 'password']);
      if (error) return this.$toast(error);
      this.$indicator.open({
        spinnerType: "fading-circle",
      });
      this.$NProgress.start();
      try {
        // 自定义事件
        let pixel = window.Vue.$route.query['pixel'] || localStorage['pixel']
        if (pixel) {
          Vue.analytics.fbq.event('06_REGISTER', { content_name: '06_REGISTER' })
        }
        // Google Analytics  REGISTER
        if (localStorage['ga']) {
          window.gtag('event', 'REGISTER')
        }
      } catch(e) {
        console.log(e)
      }
      let data = {
        phone: this.form.phone.replace(/\s/g, ""),
        password: this.form.password,
        comfirmPassword: this.form.comfirmPassword,
        validateCode: this.form.validateCode,
        isValidateCode: 'Y'
      }
      await this.$axios({
        method: "post",
        url: registeredUser,
        data: data,
      })
        .then(async (e) => {
          if (e.status.code == "000") {
            this.SET_ACCESS_TOKEN(e.body.accessToken);
            this.SET_RECORD_ITEM({
              key: "loginPhone",
              value: this.form.phone,
            });
            this.SET_RECORD_ITEM({
              key: "loginPassword",
              value: this.form.password,
            });
            await this.getUser();
          } else if (e.status.code === '986') {
            this.SET_RECORD_ITEM({
              key: "loginPhone",
              value: this.form.phone,
            });
            this.$emit('input', 'login')
            this.$toast(e.status.msg)
          } else {
            this.$toast(e.status.msg)
          }
        })
        .catch(() => {});
      this.$indicator.close();
      this.$NProgress.done();
      branch.logEvent('06_REGISTER', { metadata: data })
    },
  }
}
</script>

<style lang="scss" scoped>
.register {
  background: white;
  .ivr_box {
    padding: 10px 0;
  }
  .content {
    padding: 10px 10px 0px 10px;
    .item {
      margin-top: 20px;
      .mint-cell {
        background: #F7F8FA;
        /deep/ .mint-cell-wrapper {
          background: none;
        }
        /deep/ input {
          background: #F7F8FA;
        }
      }
      .title {
        margin: 6px 15px;
        font-size: 16px;
        color: #1D1D1D;
      }
      .phone {
        border-radius: 6px;
        display: flex;
        background-color: #F7F8FA;
        .mint-cell {
          flex: 1;
        }
        &::before {
          content: '+52';
          color: #111111;
          padding-left: 10px;
          line-height: 46px;
        }
      }
      .mint-field {
        border-radius: 6px;
        min-height: 46px;
      }
      .code {
        display: flex;
        .mint-field {
          flex: 1;
        }
        .mint-button {
          background-color: #4F9B41;
          color: white;
          border-radius: 6px;
          height: 46px;
          padding: 0px 25px;
          margin-left: 4px;
          font-size: 13px;
        }
      }
    }
  }
  .ivr {
    color: #1D1D1D;
    margin: 10px 15px 0px 15px;
    .mint-button {
      color: #1D1D1D;
      border-color: #1D1D1D;
    }
  }
  .bottom {
    padding: 40px 10px 20px 10px;
    .mint-button {
      border-radius: 6px;
      font-size: 16px;
      background-color: #4F9B41;
      &:last-child {
        margin-top: 10px;
      }
    }
  }
  .visible {
    margin-left: 5px;
  }
  .login {
    text-align: center;
    padding-bottom: 20px;
    .btn {
      color: #4F9B41;
      text-decoration: underline;
    }
  }
}
</style>