var error = 'error'; // 错误
var success = 'success'; // 成功
var warning = 'warning'; // 警告

export function emptyRules(val) {
  if (val) {
    return success
  }
  return error
}

export function nameRules(val) {
  if (val) {
    if (/^[a-zA-Z][a-zA-Z ]{1,40}[a-zA-Z]$/.test(val)) {
      return success
    }
    return warning
  }
  return error
}

export function phoneRules(val) {
  if (val) {
    val = val.replace(/\s/g, '')
    if (/^(0|52)?[1-9]{1}[0-9]{9}$/.test(val)) {
      return success
    }
    return warning
  }
  return error
}

export function companyPhoneRules(val) {
  if (val) {
    val = val.replace(/\s/g, '')
    if (/^(0|52)?[1-9]{1}[0-9]{9}$/.test(val)) {
      return success
    }
    return warning
  }
  return error
}

export function phoneRepeatRules(val, list) {
  if (list.filter(e => e == val).length > 1) {
    return warning
  }
  return phoneRules(val)
}

export function passwordRules(val) {
  if (val) {
    if (val.length >= 6) {
      return success
    }
    return warning
  }
  return error
}

export function comfirmPasswordRules(val, oldVal) {
  if (val) {
    if (val.length >= 6) {
      if (oldVal != val) {
        return warning
      }
      return success
    }
    return warning
  }
  return error
}

export function validateCodeRules(val) {
  if (val) {
    if (val.length == 4) {
      return success
    }
    return warning
  }
  return error
}

export function bankCardRules(val) {
  if (val) {
    if (/^[0-9]{16,18}$/.test(val)) {
      return success
    }
    return warning
  }
  return error
}

export function idcardRules(val, code) {
  val = val.replace(/[^a-zA-Z0-9]/g, '')
  if (val) {
    if (code) {
      if (code == 'PY01') {
        if (/^[a-zA-Z0-9]{9}$/.test(val)) {
          return success
        }
      } else if (code == 'PY03') {
        if (/^\d{7}$/.test(val)) {
          return success
        }
      } else if (code == 'PY04') {
        if (/^\d{12}$/.test(val)) {
          return success
        }
      } else if (code == 'PY07') {
        if (/^[a-zA-Z0-9]{23,24}$/.test(val)) {
          return success
        }
      } else if (code == 'PY08') {
        if (/^\d{12}$/.test(val)) {
          return success
        }
      } else if (code == 'PY09') {
        if (/^[a-zA-Z0-9]{12}$/.test(val)) {
          return success
        }
      } else if (code == 'PY10') {
        if (/^[a-zA-Z0-9]{15}$/.test(val)) {
          return success
        }
      } else if (code == 'PY11') {
        if (/^[a-zA-Z0-9]{17}$/.test(val)) {
          return success
        }
      } else if (code == 'PY05') {
        if (/^\d{10}$/.test(val)) {
          return success
        }
      } else if (code == 'PY06') {
        if (/^[a-zA-Z0-9]{15}$/.test(val)) {
          return success
        }
      } else if (code == 'PY02') {
        if (/^[a-zA-Z0-9]{11}$/.test(val)) {
          return success
        }
      } else if (/^[a-zA-Z0-9]{6,18}$/.test(val)) {
        return success
      }
    } else if (/^[a-zA-Z0-9]{6,18}$/.test(val)) {
      return success
    }
    return warning
  }
  return error
}

export function emailRules(val) {
  if (val) {
    if (/^.+@.+$/.test(val)) {
      return success
    }
    return warning
  }
  return error
}
